@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  background-color: #000000e1 !important;
  overflow-x: hidden;

}
.playfair-display {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}


.swiper-button-next,
.swiper-button-prev {
  color: #b12222 !important;
}

.swiper-pagination-bullet {
  background-color: #b12222 !important;
}


.flex {
  display: flex;
}

.none {
  display: none;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding-bottom: 10%;
  justify-content: center;
  background-color: #092714;
  animation: opacity .5s ease;
}


@keyframes opacity {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}


.bg-transport {
  background-image: url('assets/9.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
  /* Adjust the duration for speed */
}

.icon {
  position: relative;
  left: -200px; /* Początkowa pozycja */
  transition: left 1s;
}
.animate-icon {
  left: 0; /* Pozycja docelowa */
}